import * as css from './BlogArticleCategories.css'
import { Box, Text } from 'atomic'
import { themeVars } from 'atomic/styles/theme.css'
import { Categories } from './Categories'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'

type BlogArticleCategoriesProps = {
  categoriesNames: string[]
  className?: string
  isFeatured?: boolean
} & BuilderProps

const BlogArticleCategories: React.FC<BlogArticleCategoriesProps> = ({ categoriesNames, isFeatured, ...props }) => {
  const activeCategories = Object.keys(Categories).filter((key) => categoriesNames?.includes(Categories[key]?.name))

  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Box {...cleanProps} display={'flex'} gap={'xxxs'} className={!isFeatured ? css.categories : ''}>
      {activeCategories?.map((category) => (
        <Box
          key={category}
          background={Categories[category]?.color}
          borderRadius="xl"
          paddingLeft="none"
          paddingRight="none"
          paddingTop="none"
          paddingBottom="none"
        >
          <Text
            fontSize="xs"
            fontWeight="semibold"
            margin="none"
            padding="xxs"
            lineHeight="xxxl"
            style={{ paddingBlock: themeVars.spaces.xxxs, paddingInline: themeVars.spaces.xs }}
          >
            {Categories[category]?.name}
          </Text>
        </Box>
      ))}
    </Box>
  )
}

export default BlogArticleCategories
