import { Box, Text, Heading } from 'atomic'
import { Link } from 'components/link/Link'
import React, { FC } from 'react'
import Image from 'next/image'
import { BlogData } from 'hooks/useFetchRelatedPosts'
import { themeVars } from 'atomic/styles/theme.css'
import { postHoverEffect } from '../blog_overview/blogoverview.css'
import BlogArticleCategories from '../blog_article_categories/BlogArticleCategories'
import { useRouter } from 'next/router'

type BlogArticlePreviewProps = {
  article: BlogData
  startDate?: number
  createdDate?: number
  fixedImageHeight?: boolean
}

const BlogArticlePreview: FC<BlogArticlePreviewProps> = ({ article, startDate, createdDate, fixedImageHeight }) => {
  const router = useRouter()
  // if article has an scheduled date show that, otherwise show the first published date
  const date = new Date(article?.startDate ? article?.startDate : article?.createdDate ? article?.createdDate : startDate ? startDate : createdDate)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const formattedDate = date.toLocaleDateString(router.locale, options)
  return (
    <Link href={`/stories/${article?.slug}`} key={article?.slug} style={{ outline: 'none' }}>
      <Box position={'relative'} style={{ breakInside: 'avoid', transition: 'all 0.7s ease' }} className={postHoverEffect} marginBottom={'l'}>
        <Image
          src={article?.image}
          alt={article?.title}
          width={0}
          height={0}
          sizes="100vw"
          style={{ width: '100%', height: fixedImageHeight ? 400 : 'auto', marginBottom: themeVars.spaces.m, objectFit: 'cover' }}
        />
        <Box display={'flex'} flexDirection={'column'} gap={'s'}>
          <Text fontSize={'s'} fontWeight={'semibold'} color={'malm0'} margin={'none'}>
            {formattedDate} {article?.readingTime ? ` / ${article?.readingTime} min read` : ''}
          </Text>
          <Box display={'flex'} flexDirection={'column'} gap={'xs'}>
            <Heading as="h4" fontSize={'l'} color={'malm0'} margin={'none'}>
              {article?.title}
            </Heading>
            <Text fontSize={'base'} color={'malm0'} margin={'none'}>
              {article?.intro}
            </Text>
          </Box>
        </Box>
        <BlogArticleCategories categoriesNames={article?.categories} />
      </Box>
    </Link>
  )
}

export default BlogArticlePreview
