import DynamicComponent from 'components/StoryblokComponents'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect } from 'react'
import { AppStoreData } from 'types/AppStoreData'
import { StoryblokContent } from 'types/StoryblokContent'
import { PageAlternateLink, useLink } from '../../context/LinkContext'
import msClarity from '../../lib/ms-clarity'
import { BuilderContent } from '@builder.io/sdk'
import { BuilderComponent } from '@builder.io/react'
import { useOuCookies } from 'components/cookiebanner/CookieBanner'
import { ModelType } from 'utils/cms-helper'
import { themeVars } from 'atomic/styles/theme.css'
import { Button } from 'atomic'
import { Link } from 'components/link/Link'
import RelatedStories from 'components/related_stories/RelatedStories'

type PropTypes = {
  storyblokContent?: StoryblokContent
  appStoreData?: AppStoreData
  builderContent?: BuilderContent
  pageAlternatesForBuilder?: PageAlternateLink[]
  contentType?: ModelType
}

type MetaInfo = {
  title?: string
  description?: string
  noindex?: boolean
  open_graph_image?: {
    filename: string
  }
}

const Page: FC<PropTypes> = ({ storyblokContent, builderContent, appStoreData, pageAlternatesForBuilder, contentType }) => {
  const { consentAnalytical } = useOuCookies()
  const router = useRouter()
  const host = 'https://onceupon.photo'
  const { getLocaleTitle, getPageAlternates, getCanonicalLocale, getPathWithoutTrailingSlash } = useLink()
  const pageAlternates: PageAlternateLink[] = getPageAlternates(router.asPath, host, router.defaultLocale)

  const prodEnvironment = process.env.NODE_ENV === 'production'
  const localesToDefaultInCanonical: string[] = ['en-gb', 'en-au', 'en-us', 'en-ca']
  const canonicalLocale = getCanonicalLocale(router.asPath, router.locale, router.defaultLocale)
  const canonicalLocaleAsPath = localesToDefaultInCanonical.indexOf(canonicalLocale) > -1 ? '' : `/${canonicalLocale}`
  const pathWithoutTrailingSlash = getPathWithoutTrailingSlash(router.asPath)
  const pageId = storyblokContent?.id ?? builderContent?.data?.id
  const renderContent = (content: StoryblokContent): JSX.Element => {
    if (!content.body) {
      return <DynamicComponent blok={content} />
    }
    return content.body.map((blok: StoryblokContent) => <DynamicComponent blok={blok} key={blok._uid} />)
  }

  const renderBuilderContent = (content: BuilderContent): JSX.Element => {
    const related = content?.data?.relatedposts?.map((relatedPost: { post: { id: string } }) => relatedPost?.post?.id)
    return (
      <>
        <BuilderComponent locale={router.locale} model={contentType} content={content} />
        <RelatedStories relatedPosts={related} />
      </>
    )
  }

  const setFirstImgAltToH1TextIfNotAlreadySet = (): void => {
    const h1 = document.getElementsByTagName('h1')[0]
    const image = document.getElementsByTagName('img')[0]
    if (h1 && image && image.alt?.length === 0) {
      image.alt = h1.innerText
    }
  }

  useEffect(() => {
    setFirstImgAltToH1TextIfNotAlreadySet()
  }, [])

  let metaObject: MetaInfo = storyblokContent
    ? storyblokContent.meta?.length > 0
      ? storyblokContent?.meta[0]
      : undefined
    : {
        noindex: builderContent?.data?.noindex ?? false,
        description: builderContent?.data?.description ?? '',
        title: builderContent?.data?.title ?? '',
        open_graph_image: builderContent?.data?.ogimage ? { filename: builderContent?.data?.ogimage } : undefined,
      }

  // implemented because Facebook needs a og:locale meta-tag with underscore instead of dash and capital variant, i.e. 'en-gb' will become 'en_GB'
  const localeParts = router.locale.split('-')
  const localeWithUnderscore = localeParts.length === 2 ? localeParts[0] + '_' + localeParts[1].toUpperCase() : router.locale

  if (router.asPath.indexOf('/stories-old') === 0) {
    metaObject = {
      noindex: true,
      description: '',
      title: '',
      open_graph_image: undefined,
    }
  }

  const title = metaObject?.title ?? getLocaleTitle(router.asPath, router.locale)
  return (
    <>
      <Head>
        <title>{title}</title>
        {metaObject ? (
          <>
            {metaObject.noindex ? <meta name="robots" content="noindex" /> : <meta name="robots" content="index, follow" />}
            <meta name="description" content={metaObject.description} />
            <meta name="twitter:description" content={metaObject.description} />
            <meta property="og:description" content={metaObject.description} />
            {/* <meta name="title" content={metaObject.title} /> */}
            <meta property="og:title" content={metaObject.title} />
            <meta name="twitter:title" content={metaObject.title} />
            <meta property="og:image" content={metaObject.open_graph_image?.filename} />
          </>
        ) : (
          <>
            <meta name="robots" content="index, follow" />
            <meta name="description" content={storyblokContent.meta_description} />
            <meta name="twitter:description" content={storyblokContent.meta_description} />
            <meta property="og:description" content={storyblokContent.meta_description} />
            {/* <meta name="title" content={storyblokContent.meta_title} /> */}
            <meta property="og:title" content={storyblokContent.meta_title} />
            <meta name="twitter:title" content={storyblokContent.meta_title} />
          </>
        )}
        <meta property="og:url" content={`${host}${canonicalLocaleAsPath}${pathWithoutTrailingSlash}`} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={localeWithUnderscore} />
        {appStoreData && pageId === 'photobooks' && (
          <Script
            id="app-store-data"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(appStoreData) }}
            key="product-jsonld"
          />
        )}
        <link rel="canonical" href={`${host}${canonicalLocaleAsPath}${pathWithoutTrailingSlash}`} />
        {pageAlternatesForBuilder?.map((alt, index) => (
          <link rel="alternate" hrefLang={alt.hreflang.toLowerCase()} href={alt.href.toLowerCase()} key={`${alt.href}${index}`} />
        ))}
        {!pageAlternatesForBuilder
          ? pageAlternates.map((alternate, index) => {
              return <link rel="alternate" hrefLang={alternate.hreflang} href={alternate.href} key={index} />
            })
          : null}
      </Head>

      {consentAnalytical && prodEnvironment && <Script id="ms-clarity">{msClarity}</Script>}
      <main
        style={{
          backgroundColor: builderContent?.data?.backgroundColor?.value.data.color ? builderContent.data.backgroundColor?.value.data.color : null,
          margin: builderContent?.data?.backgroundColor?.value.data.color ? themeVars.spaces.s : null,
          position: 'relative',
        }}
      >
        {builderContent ? renderBuilderContent(builderContent) : storyblokContent ? renderContent(storyblokContent) : <></>}{' '}
        {builderContent && builderContent.data?.stickyButton && (
          <Link href={builderContent.data?.stickyButtonSettings?.link}>
            <Button
              variant={builderContent.data?.stickyButtonSettings?.type ?? 'primary'}
              size={builderContent.data?.stickyButtonSettings?.size ?? 'l'}
              style={{
                position: 'fixed',
                bottom: themeVars.spaces['s'],
                left: '50%',
                transform: 'translate(-50%, -50%)',
                paddingLeft: themeVars.spaces['xl'],
                paddingRight: themeVars.spaces['xl'],
                zIndex: 5,
              }}
            >
              {builderContent.data?.stickyButtonSettings?.label ?? 'Button'}
            </Button>
          </Link>
        )}
      </main>
    </>
  )
}

export default Page
